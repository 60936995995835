<template>
  <div id="overlay" v-scroll-lock="true" :class="status">
    <div class="faux-bg" @click="close()"></div>
    <div class="container">
      <div class="inner">
        <div class="controls close" @click="close()"></div>
        <div v-if="showControls && showNext" class="controls next" @click="next()">
          <inline-svg :src="$getImageSrc('little-arrow')"></inline-svg>
        </div>
        <div v-if="showControls && showPrev" class="controls prev" @click="prev()">
          <inline-svg :src="$getImageSrc('little-arrow')"></inline-svg>
        </div>

        <div class="img-wrapper is-cover" :class="imgRatio">
          <craft-image-helper :src="slide.image"></craft-image-helper>
          <!--<img :src="$getImageSrc(slide.image)">-->
        </div>

        <ul>
          <li class="title">{{slide.title}}</li>
          <li class="byline" v-if="slide.byline">{{slide.byline}}</li>
          <li class="content" v-html="slide.content"></li>
          <li v-if="slide.linkedIn" class="linked-in">
            <a :href="slide.linkedIn" target="_blank">
              <inline-svg :src="$getImageSrc('linked-in')"></inline-svg>
            </a>
          </li>
          <li class="link" v-if="slide.link">
            <a :href="slide.link" target="_blank" class="has-arrow">
              {{slide.linkTitle}}
              <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg> 
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>


<script>
  // $.overlayData object
  // ---
  // active: Number, // optional
  // loop: Boolean, // optional
  // slides: [
  //   {
  //     title: String, 
  //     byline: String, // optional
  //     content: String, 
  //     image: String, 
  //     link: String, // optional
  //     linkTitle: String // optional
  //   }
  // ]

  export default {
    components: {  },
    data(){
      return {
        activeIndex: 0,
        status: [],
      }
    },
    computed: {
      data() {
        return this.$store.state.overlayData
      },
      slide() {
        return this.data.slides[this.activeIndex]
      },
      showControls() {
        return this.data.slides.length > 1
      },
      showPrev() {
        return (!this.data.loop && this.activeIndex === 0) ? false : true;
      },
      showNext() {
        return (!this.data.loop && this.activeIndex === this.data.slides.length-1) ? false : true;
      },
      imgRatio() {
        let breakpoint = this.$store.state.breakpoint

        if(breakpoint === 'xs') {
          return 'one-one'
        } else if (breakpoint === 'sm') {
          return 'three-two'
        } else {
          return 'three-four'
        }
      }
    },
    methods: {
      close() {
        this.status.push('outro')

        setTimeout(() => {
          this.$store.state.overlayData = null
        }, 750); // see overlay.scss
      },
      next() {
        if(this.activeIndex !== this.data.slides.length-1) {
          this.activeIndex++
        } else {
          this.activeIndex = 0
        }
      },
      prev() {
        if(this.activeIndex !== 0) {
          this.activeIndex--
        } else {
          this.activeIndex = this.data.slides.length-1
        }
      },
    },
    watch: {
    },
    mounted() {
      this.$updateCssVars();
      this.activeIndex = (this.data.active !== undefined) ? this.data.active : 0;

      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.close()
        }
        if (e.key == 'ArrowRight' && this.showControls) {
          this.next()
        }
        if (e.key == 'ArrowLeft' && this.showControls) {
          this.prev()
        }
      });

      setTimeout(() => {
        this.status.push('active')
      }, 50);
    }
  }
</script>