
import Home from './components/pages/Home'
import FamilyOffice from './components/pages/FamilyOffice'
import Investments from './components/pages/Investments'
import Foundation from './components/pages/Foundation'
import News from './components/pages/News'
import Article from './components/pages/Article'
import Contact from './components/pages/Contact'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import ErrorPage from './components/pages/Error'

import { EventBus } from '/event-bus.js';
import Store from './store.js'

let routes = [
    
    {   
        path: '/', // ensure home is first item - it is
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },

    {
        path: '/family-office',
        name: 'family-office',
        component: FamilyOffice,
        meta: { title: 'Family Office' },
    },

    {
        path: '/investments',
        name: 'investments',
        component: Investments,
        meta: { title: 'Investments' },
    },

    {
        path: '/foundation',
        name: 'foundation',
        component: Foundation,
        meta: { title: 'Foundation' },
    },

    {
        path: '/news',
        name: 'news',
        component: News,
        meta: { title: 'News' },
    },

    {
        path: '/news/:slug', 
        name: 'article',
        component: Article,
      
    },

    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },

    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' },
    },

    { 
        path: "*", // ensure Error is last item
        name: 'error',
        component: ErrorPage,
        meta: { 
            title: 'Error!',
            hidden: true 
        },
    }
]


export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) { 
        // see layouts/default for Emit handling
        if(!to.hash && to.name !== from.name) { 
            // page change without hash
            EventBus.$emit('routerEvent',{scroll:false});
            return { x: 0, y: 0 }  
        } else if(to.hash && to.name !== from.name) { 
            // page change with hash
            EventBus.$emit('routerEvent',{scroll:true, timeout:350}); 
            return { x: 0, y: 0 }
        } else { 
            // internal hash change 
            if(Store.state.newLoad) { 
                // add cozy delay for refresh / direct hash links
                EventBus.$emit('routerEvent',{scroll:true, timeout:500});
            } else { 
                // no delay for internal events
                EventBus.$emit('routerEvent',{scroll:true, timeout:0});
            }
        }
    },

}