<!-- 
  PARENT TEMPLATE
  --- 
  <template>
    <carousel-helper :options="options" :custom-nav="" :custom-pagination="" :debug="">
      <swiper-slide>
      </swiper-slide>
    </carousel-helper>
  </template>

  <script>
    import CarouselHelper from '~/components/CarouselHelper.vue'
    import { SwiperSlide } from 'vue-awesome-swiper'

    export default {
      components: { CarouselHelper, SwiperSlide },
      data() {
        return {
          options: {
            // https://swiperjs.com/api/
            // https://github.surmon.me/vue-awesome-swiper/
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            slidesPerGroup: 1,
            grabCursor: true,
            // autoplay: 3000,
            breakpoints: {
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              576: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
            }
          }
        }
      }
    }
  </script>
-->

<template>
  <div class="carousel-wrapper" @mouseover="hover = true" @mouseleave="hover = false">
    <template v-if="customNav && !options.navigation && totalSlides > groupSize">
      <div @click="prev" class="prev" :class="{'disabled' : isDisabled('prev')}"></div>
      <div @click="next" class="next" :class="{'disabled' : isDisabled('next')}"></div>
    </template>

    <swiper 
      :options="options" 
      @ready="ready" 
      @slideChange="slideChange"
      @clickSlide="clickSlide" 
      @resize="resize"
      :key="options.loop"
      ref="swiper"
      class="swiper">

      <slot></slot>

      <!-- conditionally include default pagination / navigation -->
      <div v-if="!customPagination && options.pagination" class="swiper-pagination" slot="pagination"></div>
      <template v-if="!customNav && options.navigation">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </template>

    </swiper>

    <div class="count">{{activeSlide + 1}}<span></span>{{totalSlides}}</div>

    <pre v-if="debug">
      activeSlide : {{activeSlide}}
      groupSize : {{groupSize}}
      totalSlides : {{totalSlides}}
      pagination : {{pagination}}
      activeDot : {{activeDot}}
    </pre>

    <div v-if="customPagination && !options.pagination && pagination && totalSlides > groupSize" class="pagination">
      <span 
        v-for="(foo,index) in pagination" 
        :key="index" 
        :class="{'active' : index === activeDot}"
        @click="slideTo(index)">
      </span>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '/event-bus.js';
  import { Swiper } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    components: { Swiper },
    props: {
      options: {
        type: Object,
        required: true
      },
      customNav: {
        type: Boolean,
        default: false
      },
      customPagination: {
        type: Boolean,
        default: false
      },
      debug: Boolean
    },
    data() {
      return {
        init: null,
        activeSlide: 0,
        groupSize: null,
        totalSlides: null,
        pagination: null,
        activeDot: 0,
        defaultLoopSetting: null,
        hover: false,
        autoplayInterval: null,
      }
    },
    methods: {
      clickSlide() {
      //   console.log('clicked')
      },
      ready() {
        // console.log('ready')
        // console.log(this.$refs.swiper.$swiper)
        this.setTotalSlides()
        this.setGroupSize()
        this.overrideParams()
        this.setPagination()
        this.startAutoplay()
        this.init = true
      },
      overrideParams() {
        if(this.groupSize >= this.totalSlides) {
          // console.log('Overriding passed options')
          this.options.loop = false
          this.options.grabCursor = false
          this.options.simulateTouch = false
        }
      },
      setTotalSlides() {
        let obj = this.$refs.swiper.$swiper.slides
        let result = 0
        if(this.options.loop) {
          result = 1
        }
        Object.keys(obj).forEach(function (key) {
          if(key !== 'length' && !obj[key].classList.value.includes('duplicate')) {
            result++ 
          }
        });
        this.totalSlides = result
      },
      setPagination() {
        if(this.groupSize === 1) {
          this.pagination = this.totalSlides
          this.activeDot = this.activeSlide
        } else {
          this.pagination = Math.ceil(this.totalSlides / this.groupSize)
          if(this.options.loop) {
            this.activeDot = Math.floor(this.activeSlide / this.groupSize)
          } else {
            this.activeDot = Math.ceil(this.activeSlide / this.groupSize)
          }
        }
      },
      setGroupSize() {
        this.groupSize = this.$refs.swiper.$swiper.params.slidesPerGroup
      },
      resize() {
        this.setGroupSize()
        this.setPagination()
        this.overrideParams()
      },
      slideTo(index) {
        if(this.groupSize === 1) {
          // console.log('Sliding to slide : ' + index)
          this.$refs.swiper.$swiper.slideToLoop(index)
        } else {
          // console.log('Sliding to group : ' + index * this.groupSize)
          this.$refs.swiper.$swiper.slideToLoop(index * this.groupSize)
        }
      },
      prev() {
        // console.log('Prev')
        this.$refs.swiper.$swiper.slidePrev()
      },
      next() {
        // console.log('Next')
        this.$refs.swiper.$swiper.slideNext()
      },
      slideChange() {
        this.activeSlide = this.$refs.swiper.$swiper.realIndex
        this.setPagination()
        EventBus.$emit('slideChange', this.activeSlide);
      },
      isDisabled(btn) {
        if(!this.options.loop) {
          if(btn === 'prev' && this.activeSlide === 0 ||
             this.groupSize === 1 && btn === 'next' && this.activeSlide + 1 === this.totalSlides ||
             this.groupSize !== 1 && btn === 'next' && this.pagination === this.activeDot + 1) {
            return true
          }
        }
      },
      startAutoplay() {
        // autoplay is fucked or something, build our own shitty version
        if(this.options.autoplay) {
          let self = this
          this.autoplayInterval = setInterval(function(){
            if(!self.hover) {
              self.$refs.swiper.$swiper.slideNext()
            }
          }, self.options.autoplay);
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.autoplayInterval)
      this.autoplayInterval = null
    }
  }
</script>