<template>
  <default v-if="loaded">
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'
  import gql from 'graphql-tag'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
        title: 'Gough',
      }
    },
    apollo: {
      globalSets: { query: gql` query{
        globalSets {
          ... on featured_GlobalSet {
            featuredNews (orderBy: "postDate DESC") {
              id title date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                ... on article_default_Entry {
                  title slug category: articleCategory {
                    title
                  }
                  image: articleHeroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
              }
            }
            ...on footer_GlobalSet{
              id
              addressLine1 addressLine2 phone emailAddress linkedinUrl
            }
          }
          newsCategories: categories (group:"newsEvents"){
            ... on newsEvents_Category {
              id
              title
            }
            
          }
          peopleCategories: categories (group:"people"){
            ... on people_Category {
              id
              title
            }
          }
          articleCategories: entries(section: "article"){
            id year: postDate @formatDateTime (format: "Y", timezone: "Pacific/Auckland")
            ...on article_default_Entry{
              id, articleCategory {
                id
              }
            }
          }
        }`,
        result ({ data, loading, networkStatus }) {
          if (data.globalSets !== null){
            this.$store.commit('setFeaturedNews', data.globalSets[0].featuredNews);
            this.$store.commit('setAddressLine1', data.globalSets[1].addressLine1);
            this.$store.commit('setAddressLine2', data.globalSets[1].addressLine2);
            this.$store.commit('setPhone', data.globalSets[1].phone);
            this.$store.commit('setEmail', data.globalSets[1].emailAddress);
            this.$store.commit('setLinkedIn', data.globalSets[1].linkedinUrl);

            // Loop all articleCategories to build 'current' category list for filter
            let currentCategories = []
            let currentYears = []
            data.articleCategories.forEach(function (articleCategoryValue, articleIndex) {
              // push article year into currentYears if not already added
              currentYears.indexOf(articleCategoryValue.year) === -1 ? currentYears.push(articleCategoryValue.year) : false
              data.newsCategories.forEach(function (value, index) {
                if (articleCategoryValue.articleCategory[0].id === value.id){
                  currentCategories.indexOf(value) === -1 ? currentCategories.push(value) : false
                }
              });
            });
            this.$store.commit('setNewsCategories', currentCategories);
            this.$store.commit('setPeopleCategories', data.peopleCategories);
            this.$store.commit('setNewsYears', currentYears);
            this.$store.commit('setFeaturedNewsLoaded', true);
          } 
          else{
            this.$router.push({ name: 'errorContent' })
          }
        },
        skip() {
          return this.skipQuery
        },
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
      computedTitle() {
        //console.log(this.$store.state.craftPageData)
        if(this.$route.meta.title) {
          return `${this.title} - ${this.$route.meta.title}`
        } 
        else if (this.$store.state.pageTitle) {
          return `${this.title} - ${this.$store.state.pageTitle}`
        } else {
          return this.title
        }
      }
    },
  	methods: {
  		setup() {

        console.log('%cDesign : https://doublelux.co', 'color: #000000; font-weight: bold; font-size: 11px;');
        console.log('%cDevelopment : https://kichin.co', 'color: #000000; font-weight: bold; font-size: 11px;');
        console.log('–');
        if (!this.$store.state.featuredsNewsLoaded){
          this.$apollo.queries.globalSets.skip = false
          this.$apollo.queries.globalSets.refetch()
        }
  			this.breakpointCheck();
        this.$nextTick(()=>{
          this.cssVars();
        });
        
  		},
  		resize(e) {
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else if (w >= 1200 && w <= 1439){
          breakpoint = 'xl';
        } else {
          breakpoint = 'xxl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        this.$updateCssVars();
      }
    },
    metaInfo() {
      return {
        title: this.computedTitle
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>