<template>
  <div id="alert-bar" v-if="curItem" :class="classes" @click="clickHandler()">
    
    <div class="container" >
      <div class="item">
        <div class="blurb">{{ curItem.blurb }}</div>
      </div>
      <div class="link" v-if="curItem.slug" :key="1">
        <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
      </div>
    </div>
    
  </div>
</template>

<script>

  export default {
    components: { },
    props: {
      // data: {
      //   type: Object,
      //   required: true
      // }
    },
    data() {
      return {
        curItem: null,
        fade: false,
        data : {
          items: [
            {
              blurb: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultricies porta augue, ullamcorper gravida nunc.',
              slug: 'foo'
            },
            {
              blurb: 'Praesent eu turpis nec lorem pretium commodo nec sit amet quam.',
            },
            {
              blurb: 'Etiam fringilla urna sed sem maximus, nec dapibus diam imperdiet in enim faucibus suscipit.',
              slug: 'baz'
            },
          ]
        }
      }
    },
    computed: {
      classes() {
        let result = []
        if(this.curItem.slug !== undefined) {
          result.push('has-link')
        }
        if(this.fade) {
          result.push('fade-out')
        }
        return result
      }
    },
    methods: {
      clickHandler() {
        if(this.curItem.slug !== undefined)  {
          this.$router.push({ name: 'article', params: { slug: this.curItem.slug } })
        }
      }
    },
    mounted() {
      this.curItem = this.data.items[0]
      let curItemIndex = 0

      setInterval(() => {
        this.fade = true
        setTimeout(() => {
          this.fade = false
          curItemIndex = (curItemIndex + 1) % this.data.items.length
          this.curItem = this.data.items[curItemIndex]
        }, 500);
      }, 5000)
      
    }
  }
</script>