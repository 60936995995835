<template>
  <div class="impact-items" >
    <ul class="item" v-for="item in data" :id="$toKebab(item.title)">
      <li class="img-wrapper is-cover three-two">
        <craft-image-helper :src="item.image"></craft-image-helper>
        <!--<img :src="$getImageSrc(item.image)">-->
      </li>
      <li><h4>{{item.title}}</h4></li>
      <li v-html="item.description"></li>
      <ul v-if="item.links" class="links">
        <li v-for="link in item.links">
          <a v-if="isExternal(link.link)" :href="link.link" target="_blank" class="has-arrow">
            {{link.linkTitle}}
            <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
          </a>
          <link-helper v-else :to="link.link" class="has-arrow">
            {{link.linkTitle}}
            <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
          </link-helper>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
      
    },
    methods: {
      isExternal(link){
        return (link.includes('http')) ? true : false
      },
    },
  }
</script>