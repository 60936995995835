<template>
  <ul>
    <li>{{this.$store.state.addressLine1}}</li>
    <li>{{this.$store.state.addressLine2}}</li>
  </ul>  
</template>


<script>

  export default {
    components: { },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>