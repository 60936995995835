<template>
  <div class="people-grid">
    <h2>Our People</h2>
    <div class="category-wrapper" v-for="category in this.$store.state.peopleCategories">
      <h3>{{category.title}}</h3>
      <div class="people-wrapper">
        <ul class="person" 
            :class="{'allow-fade':person.casualImage.length}"
            v-for="(person,index) in data" 
            v-if="person.category[0].id === category.id" 
            @click="triggerPerson(index)">
          <li class="img-wrapper is-cover three-four">
            <craft-image-helper v-if="person.casualImage.length" :src="person.casualImage" class="casual"></craft-image-helper>
            <craft-image-helper :src="person.formalImage" class="formal"></craft-image-helper>
          </li>
          <li class="name">{{person.name}}</li>
          <li class="qualification" v-if="person.qualification">{{person.qualification}}</li>
          <li class="title">{{person.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import IntroModule from "~/components/IntroModule.vue";

  export default {
    components : { IntroModule },
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      triggerPerson(index){
        // format object for overlay
        let result = {
          active: index,
          loop: false,
          slides: []
        }
        this.data.forEach(function (value, index) {
          let slide = {}
          slide.title = value.name
          slide.byline = value.title
          slide.content = value.content
          slide.image = value.formalImage
          slide.linkedIn = value.linkedinUrl
          result.slides.push(slide)
        });
        // console.log(result)
        this.$store.commit('setOverlayData', result);
      },
      categories() {
        // get categories
        let result = []
        this.data.forEach(function (value, index) {
          if(!result.includes(value.category)){
            result.push(value.category)
          }
        });
        return result
      }
    }
  }
</script>