<template>
  <ul class="project-detail">
    <template v-if="data">
      <li class="is-cover three-two">
        <craft-image-helper v-if="data.imageAlt.length" :src="data.imageAlt"></craft-image-helper>
        <craft-image-helper v-else :src="data.image"></craft-image-helper>
      </li>
      <li>{{data.title}}</li>
      <li v-html="data.description"></li>
      <li class="metrics">
        <ul v-for="metric in data.metrics" class="metric">
          <li>{{metric.title}}</li>
          <li>{{metric.description}}</li>
        </ul>
      </li>
      
      <li v-if="data.link">
        <a :href="data.link" target="_blank" class="has-arrow">
          Learn more <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
        </a>
      </li>
      <li class="last" :class="{'has-link':data.link}"></li>
    </template>
  </ul>
</template>


<script>

  export default {
    props: { 
      data: Object
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    }
  }
</script>