<template>
  <div id="investments" v-if="craftLoaded && featuredNewsLoaded">

    <hero-helper :data="heroImage"></hero-helper>

    <div class="container">

      <module-wrapper>
        <intro-module :data="entry" :logo="`gough-investments`"></intro-module>
      </module-wrapper>

      <module-wrapper :jump-menu="true">
        <jump-menu></jump-menu>
      </module-wrapper>


      <module-wrapper class="bg-2" :animate="true">
        <div id="direct-investments">
          <detail-grid :data="entry.directInvestments[0]">
            <template v-slot:intro>
              <div class="intro">
                <h2>{{ entry.directInvestments[0].title }}</h2>
              </div>
            </template>
            <template v-slot:sidebar>
              <div class="sidebar">
                <div class="description" v-html="entry.directInvestments[0].description"></div>
              </div>
            </template>
          </detail-grid>
        </div>
      </module-wrapper>


      <module-wrapper :animate="true">
        <div id="alvarium">
          <h2>{{ entry.managedFunds[0].title }}</h2>
          <div class="img-wrapper is-cover one-one">
            <craft-image-helper :src="entry.managedFunds[0].image"></craft-image-helper>
          </div>
          <craft-image-helper v-if="xsMode" class="logo" :src="entry.managedFunds[0].logo"></craft-image-helper>
          <div class="description-wrapper">
            <div class="description" v-html="entry.managedFunds[0].description"></div>
            <div class="link has-arrow" @click="triggerOverlay(entry.managedFunds[0].overlay)">
              {{ entry.managedFunds[0].linkTitle }}
              <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
            </div>
            <craft-image-helper v-if="!xsMode" class="logo" :src="entry.managedFunds[0].logo"></craft-image-helper>
          </div>
        </div>
      </module-wrapper>


      <module-wrapper class="bg-2" :animate="true">
        <div id="property-development">
          <h2>{{ entry.propertyDevelopment[0].title }}</h2>
          <div class="description" v-html="entry.propertyDevelopment[0].description"></div>
          <div v-if="(entry.propertyDevelopment[0].blurbTitle || entry.propertyDevelopment[0].blurbDescription)"
            class="blurb">
            <h4 v-if="entry.propertyDevelopment[0].blurbTitle">{{ entry.propertyDevelopment[0].blurbTitle }}</h4>
            <div v-if="entry.propertyDevelopment[0].blurbDescription" class="description"
              v-html="entry.propertyDevelopment[0].blurbDescription"></div>
          </div>
          <div class="partners">
            <ul v-for="(partner, index) in entry.propertyDevelopment[0].partners" class="partner"
              @click="triggerOverlay(pdOverlayData, index, true)">
              <li class="img-wrapper is-cover one-one">
                <craft-image-helper :src="partner.image"></craft-image-helper>
                <span>{{ partner.title }}</span>
              </li>
              <li v-html="partner.description"></li>
            </ul>
          </div>
        </div>
      </module-wrapper>


      <module-wrapper :animate="true">
        <div id="investment-property">
          <h2>{{ entry.investmentProperty[0].title }}</h2>
          <div class="img-wrapper is-cover one-one">
            <craft-image-helper :src="entry.investmentProperty[0].image"></craft-image-helper>
          </div>
          <div class="description">
            <div class="description-inner" v-html="entry.investmentProperty[0].description"></div>
            
            <template v-if="(entry.investmentProperty[0].link.url !== null)">
              <a v-if="entry.investmentProperty[0].link.element === null" :href="entry.investmentProperty[0].link.url"
                target="_blank" class="has-arrow">
                {{ entry.investmentProperty[0].link.customText }}
                <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
              </a>
            
              <router-link v-else :to="convertLink(entry.investmentProperty[0].link)" class="has-arrow">
                {{ entry.investmentProperty[0].link.customText }}
                <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
              </router-link>
            </template>
          </div>
        </div>
      </module-wrapper>

      <news-teaser :data="this.$store.state.featuredNews"></news-teaser>

    </div>
  </div>
</template>

<script>
import HeroHelper from "~/components/HeroHelper.vue";
import IntroModule from "~/components/IntroModule.vue";
import JumpMenu from "~/components/JumpMenu.vue";
import ModuleWrapper from "~/components/ModuleWrapper.vue";
import DetailGrid from "~/components/DetailGrid.vue";
import NewsTeaser from "~/components/NewsTeaser.vue";
import gql from 'graphql-tag'

export default {
  components: { HeroHelper, JumpMenu, IntroModule, ModuleWrapper, DetailGrid, NewsTeaser },
  data() {
    return {
      entry: Object,
    }
  },
  computed: {
    xsMode() {
      let breakpoint = this.$store.state.breakpoint
      return (breakpoint === 'xs' || breakpoint === 'sm')
    },
    pdOverlayData() {
      let result = []
      let obj = this.entry.propertyDevelopment[0].partners
      Object.keys(obj).forEach(function (key) {
        // console.log(key)
        result.push(obj[key].overlay[0]);
      });
      return result
    },
    heroImage() {
      let heroImages = []
      let image = { heroImage: this.entry.heroImage, title: this.entry.heroImageTitle }
      heroImages.push(image)
      return heroImages
    }
  },
  apollo: {
    entry: {
      query: gql`{entry(section: "investments", ) {
                      id title slug 
                      ...on investments_investments_Entry {
                        heroImageTitle 
                        heroImage {
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
                        introTitle: investmentsIntroTitle
                        introBlurb: investmentsIntroBlurb
                        directInvestments{
                          ...on directInvestments_directInvestment_BlockType{
                            title: directInvestmentTitle
                            description
                            items{
                              ...on items_BlockType{
                                title: itemTitle
                                description: itemDescription
                                metrics{
                                  ...on metrics_metric_BlockType{
                                    title: metricTitle
                                    description: metricDescription
                                  }
                                }
                                image: itemImage{
                                  url,
                                  title,
                                  width,
                                  height,
                                  focalPoint,
                                  mimeType,
                                  s400: url @transform(width: 400),
                                  s800: url @transform(width: 800),
                                  s1200: url @transform(width: 1200),
                                  s1600: url @transform(width: 1600),
                                  s2000: url @transform(width: 2000),
                                  s2400: url @transform(width: 2400),
                                  s2800: url @transform(width: 2800),
                                  s3200: url @transform(width: 3200)
                                }
                                imageAlt: secondaryImage{
                                  url,
                                  title,
                                  width,
                                  height,
                                  focalPoint,
                                  mimeType,
                                  s400: url @transform(width: 400),
                                  s800: url @transform(width: 800),
                                  s1200: url @transform(width: 1200),
                                  s1600: url @transform(width: 1600),
                                  s2000: url @transform(width: 2000),
                                  s2400: url @transform(width: 2400),
                                  s2800: url @transform(width: 2800),
                                  s3200: url @transform(width: 3200)
                                }
                                link: itemLink
                                carousel: showOnHomePageFeaturedCarousel
                              }
                            }
                          }
                        }
                        managedFunds: managedFundsInvestments{
                          ...on managedFundsInvestments_managedFunds_BlockType{
                            title: managedFundsTitle
                            description: managedFundsDescription
                            image: managedFundsImage{
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                            carousel: managedFundsShowOnHomePage
                            logo: managedFundsLogo{
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                            linkTitle
                            overlay{
                              ...on overlay_BlockType{
                                title: overlayTitle
                                content: overlayContent
                                image: overlayImage{
                                  url,
                                  title,
                                  width,
                                  height,
                                  focalPoint,
                                  mimeType,
                                  s400: url @transform(width: 400),
                                  s800: url @transform(width: 800),
                                  s1200: url @transform(width: 1200),
                                  s1600: url @transform(width: 1600),
                                  s2000: url @transform(width: 2000),
                                  s2400: url @transform(width: 2400),
                                  s2800: url @transform(width: 2800),
                                  s3200: url @transform(width: 3200) 
                                }
                                link: overlayLink
                                linkTitle: overlayLinkTitle
                              }
                            }
                          }
                        }
                        propertyDevelopment{
                          ...on propertyDevelopment_detail_BlockType{
                            title: propertyDevelopmentTitle
                            description: propertyDevelopmentDescription
                            blurbTitle: propertyDevelopmentBlurbTitle
                            blurbDescription: propertyDevelopmentBlurbDescription
                            partners{
                              ...on partners_BlockType{
                                title: partnersTitle
                                description: partnersDescription
                                image: partnersImage{
                                  url,
                                  title,
                                  width,
                                  height,
                                  focalPoint,
                                  mimeType,
                                  s400: url @transform(width: 400),
                                  s800: url @transform(width: 800),
                                  s1200: url @transform(width: 1200),
                                  s1600: url @transform(width: 1600),
                                  s2000: url @transform(width: 2000),
                                  s2400: url @transform(width: 2400),
                                  s2800: url @transform(width: 2800),
                                  s3200: url @transform(width: 3200)
                                }
                                overlay{
                                  ...on overlay_overlay_BlockType{
                                    title: overlayTitle
                                    content: overlayContent
                                    image: overlayImage{
                                      url,
                                      title,
                                      width,
                                      height,
                                      focalPoint,
                                      mimeType,
                                      s400: url @transform(width: 400),
                                      s800: url @transform(width: 800),
                                      s1200: url @transform(width: 1200),
                                      s1600: url @transform(width: 1600),
                                      s2000: url @transform(width: 2000),
                                      s2400: url @transform(width: 2400),
                                      s2800: url @transform(width: 2800),
                                      s3200: url @transform(width: 3200)
                                    }
                                    link: overlayLink
                                    linkTitle: overlayLinkTitle
                                  }
                                }
                              }
                            }

                          }
                        }
                        investmentProperty{
                          ...on investmentProperty_detail_BlockType{
                            title: investmentPropertyTitle
                            description: investmentPropertyDescription
                            image: investmentPropertyImage{
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                            link: optionLink{
                              ...on linkField_Link{
                                element { id slug ...on EntryInterface{typeHandle slug sectionHandle } }
                                url
                                customText
                              }
                            } 
                          }
                        }
                      }
                    }
                  }`,
      result({ data, loading, networkStatus }) {
        if (data !== undefined) {
          if (data.entry !== null) {
            // gen slug ids
            data.entry.directInvestments[0].items.forEach(function (value, index) {
              value.id = this.$toKebab(value.title)
            }.bind(this));
            data.entry.propertyDevelopment[0].partners.forEach(function (value, index) {
              value.id = this.$toKebab(value.title)
            }.bind(this));
            data.entry.propertyDevelopment[0].partners.forEach(function (value, index) {
              value.id = this.$toKebab(value.title)
            }.bind(this));

            this.entry = data.entry
            // this.$store.commit('setLoaded', true)
            //console.log('GQL Data:')
            //console.log(data.entry)
            this.$store.commit('setCraftPageData', data.entry)
            this.$store.commit('setCraftLoaded', true)
          }
          else {
            this.$router.push({ name: 'error', params: { status: 500 } })
          }
        }
        else {
          this.$router.push({ name: 'error', params: { status: 500 } })
        }
      },
    }
  },
  methods: {
    triggerOverlay(data, index, loop) {
      let result = {
        slides: []
      }

      if (index) { result.active = index }
      if (loop) { result.loop = loop }

      if (Array.isArray(data)) {
        result.slides = data
      } else {
        result.slides.push(this.managedFunds.overlay)
      }

      this.$store.commit('setOverlayData', result)
    },
    convertLink(link) {
      let routeName = this.$craftVueRouteTransform(link.element.__typename, link.element.slug)
      let result = {
        name: link.element.slug
      }
      if (!routeName) {
        return result // 1:1 route name : slug
      }
      else {
        result.name = routeName
        if (link.element.slug) {
          result.params = {
            slug: link.element.slug
          }
        }
        //console.log(result)
      }
      return result
    }
  }
}
</script>