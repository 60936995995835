<template>
  <module-wrapper class="bg-2" :news="true">
    <div id="news-teaser">
      <h3 class="title">News & Events</h3>

      <carousel-helper v-if="isXs" :options="carouselOptions" :custom-nav="true">
        <swiper-slide v-for="(article, index) in articles" class="slide" :key="index">
          <article-teaser  :data="article"></article-teaser>
        </swiper-slide>
      </carousel-helper>

      <article-grid v-else>
        <article-teaser v-for="article in articles" :data="article"></article-teaser>
      </article-grid>
        
      <router-link :to="{name: 'news'}" class="has-arrow">
        View all news <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
      </router-link>
    </div>
  </module-wrapper>
</template>

<script>
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import ArticleTeaser from "~/components/ArticleTeaser.vue";
  import CarouselHelper from "~/components/CarouselHelper.vue";
  import { SwiperSlide } from 'vue-awesome-swiper'
  import LinkHelper from "~/components/LinkHelper.vue";
  
  export default {
    components : { ModuleWrapper, ArticleGrid, ArticleTeaser, CarouselHelper, SwiperSlide, LinkHelper },
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 20,
          loop: true,
          speed: 1000,
          preventClicks: false,
          preventClicksPropagation: false,
          autoHeight: true,
          // slidesPerView: 'auto',
          // autoplay: 5000,
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      articles() {
        // could probably do this cleaner with a GQL request so feel free to amend
        let breakpoint = this.$store.state.breakpoint
        if (breakpoint === 'xs') {
          return this.data.slice(0,5)
        } else if (breakpoint === 'sm' || breakpoint === 'md') {
          return this.data.slice(0,2)
        } else {
          return this.data.slice(0,3)
        }
      }
    },
    methods: {
    },
    mounted() {
    }
  }
</script>s