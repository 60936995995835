<template>
  <div class="next-prev-article">
    <router-link v-if="prevEntry !== null" :to="{name: 'article', params: {slug: prevEntry.slug}}" class="prev has-arrow">
      <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
      Previous
    </router-link>
    <router-link v-if="nextEntry !== null" :to="{name: 'article', params: {slug: nextEntry.slug}}" class="next has-arrow">
      <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
      Next
    </router-link>
  </div>
</template>


<script>
  import gql from 'graphql-tag'
  export default {
    components: { },
    props: {
      id: 0,
    },
    data(){
      return {
        prevEntry: Object,
        nextEntry: Object
      }
    },
    apollo: {
      prevEntry: {
        query: gql` query variables($id: Int)
          {
            prevEntry: entry(section: "article", prevSiblingOf: $id) {
              id
              title
              slug
              uri
            }
            nextEntry: entry(section: "article", nextSiblingOf: $id) {
              id
              title
              slug
              uri
            }
          }`,
        variables() { 
            return {id: parseInt(this.id)}
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.prevEntry !== null || data.nextEntry !== null){
              this.prevEntry = data.prevEntry
              this.nextEntry = data.nextEntry
              // console.log('GQL Data:')
              // console.log(data.prevEntry)
              // console.log(data.nextEntry)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>