<template>
  <div id="contact" v-if="craftLoaded && featuredNewsLoaded">
    <hero-helper :data="heroImage"></hero-helper>

    <form-helper id="contactForm"  class="container module-wrapper" :recaptcha="true">
      <input type="hidden" name="form_id" value="1">
      <input type="text"
             name="name"
             placeholder="Name">
      <input type="text"
             name="company"
             placeholder="Company / Organisation">
      <input type="text"
             name="phone"
             placeholder="Phone Details">
      <input type="email"
             name="email"
             placeholder="Email">
      <label for="message">Message</label>
      <textarea name="message"></textarea>
    </form-helper>

  </div>
</template>

<script>
  import HeroHelper from '~/components/HeroHelper'
  import FormHelper from '~/components/FormHelper'
  import gql from 'graphql-tag'

  export default {
    components: { FormHelper, HeroHelper },
    data(){
      return {
        entry: Object,
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
        form_id: 1,
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "contact", ) {
                      id title slug 
                      ...on contact_contact_Entry {
                        heroImageTitle 
                        heroImage {
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              // this.$store.commit('setLoaded', true)
              //console.log('GQL Data:')
              //console.log(data.entry)
              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
      heroImage(){
        let heroImages = []
        let image = {heroImage: this.entry.heroImage, title: this.entry.heroImageTitle}
        heroImages.push(image)
        return heroImages
      }
    }
  }
</script>