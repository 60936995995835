<template>
  <div class="title-with-icon" :class="classes">
    <h2>
      <inline-svg :src="$getImageSrc($toKebab(data.title))"></inline-svg>
      {{data.title}}
    </h2>
    <div v-if="data.description" class="description-wrapper">
      <div class="description" v-html="data.description"></div>
      <template v-if="data.link">
        <a v-if="isExternal" :href="data.link.url" target="_blank" class="has-arrow">
          {{data.linkTitle}}
          <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
        </a>
        <router-link v-else :to="convertLink(data.link)" class="has-arrow">{{data.link.customText}}<inline-svg :src="$getImageSrc('big-arrow')"></inline-svg></router-link>
      </template>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import LinkHelper from "~/components/LinkHelper.vue";

  export default {
    components : { LinkHelper },
    props: {
      data: {
        type: Object,
        required: true
      },
      swap: Boolean
    },
    data() {
      return {
      }
    },
    computed: {
      xsMode() {
        let breakpoint = this.$store.state.breakpoint
        return (breakpoint === 'xs' || breakpoint === 'sm') ? true : false
      },
      classes() {
        let result = []
        if(Object.keys(this.$slots).length) {
          result.push('has-content')
        }
        if(this.swap && this.xsMode) {
          result.push('swap-xs')  
        }
        return result
      },
      isExternal(){
        if (this.data.link.customText === undefined){
          return (this.data.link && this.data.link.includes('http')) ? true : false
        }
        else{
          return (this.data.link.element === null) ? true : false
        }
      }
    },
    methods:{
      convertLink(link) {
        let routeName = this.$craftVueRouteTransform(link.element.__typename,link.element.slug)
        let result = {
            name: link.element.slug
          }
        if (!routeName){
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (link.element.slug) {
            result.params = {
              slug: link.element.slug
            }
          }
          //console.log(result)
        }
        return result
      }
    }
  }
</script>