<template>
  <div class="image-grid"
       :class="{active: active}">
    <div class="image-wrapper is-cover one-one">
      <div class="inner">
        <div v-for="(imageItem,index) in data.images" class="is-cover col" :class="getImgClasses(index)">
          <craft-image-helper :src="arrayify(imageItem)"></craft-image-helper>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="inner">
        <h2>{{data.title}}</h2>
        <div class="content" v-html="data.content"></div>
        <router-link :to="transposeRouterLink(data.linkItem)" class="has-arrow">
          {{data.linkLabel}}
          <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
        </router-link>
      </div>
    </div>  
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        active: false,
      }
    },
    computed: {
      
    },
    methods: {
      arrayify(image){
        let imageSet = []
        imageSet.push(image)
        return imageSet
      },
      getImgClasses(index) {
        let imgCount = this.data.images.length
        if(index === 0) {
          if(imgCount === 1) {
            return 'span-2-col span-2-row'
          } else {
            return 'span-2-col'
          }
        } else if (index === 1) {
          if(imgCount === 2) {
            return 'span-2-col'
          }
        }
      },
      transposeRouterLink(link){
        // console.log(link)
        let routeName = this.$craftVueRouteTransform(link[0].__typename,link[0].slug)
        let result = {
            name: link[0].slug
          }
        if (!routeName){
          // console.log(result)
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (link[0].slug) {
            result.params = {
              slug: link[0].slug
            }
          }
          console.log(result)
        }
        return result
      },
    },
    mounted() {
    },
  }
</script>