<template v-if="craftLoaded">
  <div id="hero" :class="{ready:ready}" >
    <div class="hero-inner is-cover">
      <transition-group name="fade">
        <craft-image-helper :src="data[index].heroImage" :key="index"></craft-image-helper>
      </transition-group>
      <div class="title container" :class="{inset:isHome}">
        <anime-text v-if="ready" :text="heroTitle" :duration="heroDuration"></anime-text>
      </div>
    </div>
  </div>
</template>

<script>
  import AnimeText from "~/components/AnimeText.vue";
  import { EventBus } from '/event-bus.js';

  export default {
    components : { AnimeText },
    props: {
      data: {
        type: Array,
        required: true
      },
      duration: Number
    },
    data() {
      return {
        index: 0,
        ready: false,
        introDelay: 750
      }
    },
    computed: {
      heroTitle() {
        return this.data[this.index].title
      },
      heroDuration() {
        if(this.duration) {
          return this.duration
        } else {
          return (Object.keys(this.data).length > 1) ? 8000 : false
        }
      },
      isHome() {
        this.$route.name === 'home'
      }
    },
    methods: {
      changeHero() {
        if(this.index !== this.data.length -1) {
          this.index++
        } else {
          this.index = 0
        }
        this.heroTimer()
      },
      heroTimer() {
        if(this.heroDuration) {
          setTimeout(() => {
            this.changeHero()
          }, this.heroDuration);
        }
      },
      preload() {
        let self = this
        this.data.forEach(function (value, index) {
          if(index !== 0) { // preload the load the other images
            let image = new Image();
            image.src = value.heroImage[0]
          }
        });
      },
      // OLD but leaving here in case
      // loaded() { OLD but leaving here in case
      //   //console.log('hero helper loaded call')
      //   // EventBus.$on('imgSrc', (data) => {
      //   //   console.log('detected imgSrc ' + data)
      //   //   this.ready = true
      //   // })
      //   // EventBus.$on('injectedSize', (data) => {
      //   //   console.log('detected image size ' + data)
      //   //   this.ready = true
      //   // })
      //   // if(!this.ready) { // load the first image organically
      //   //   this.ready = true
      //   //   this.heroTimer()
      //   //   this.preload()
      //   // }
      // }
    },
    mounted() {
      if(!this.ready) { // load the first image organically
        this.ready = true
        this.heroTimer()
        this.preload()
      }
    },
  }
</script>