<template>
  <div id="foundation" v-if="craftLoaded && featuredNewsLoaded">

    <hero-helper :data="heroImage"></hero-helper>

    <div class="container">

      <module-wrapper>
        <intro-module :data="entry" :logo="`ben-gough-family-foundation`"></intro-module>
      </module-wrapper>

      <module-wrapper :jump-menu="true">
        <jump-menu></jump-menu>
      </module-wrapper>

      <module-wrapper class="module-wrapper bg-2" :animate="true">
        <div id="our-foundation">
          <title-with-icon :data="entry.ourFoundation[0]" :swap="true">
            <div class="image-video-wrapper">
            <craft-image-helper v-if="entry.ourFoundation[0].image.length" :src="entry.ourFoundation[0].image"></craft-image-helper>
            <template v-else>
              <video-helper v-if="entry.ourFoundation[0].video" :src="entry.ourFoundation[0].video">
                <span>Watch Video</span>
              </video-helper>
            </template>
          </div>
          </title-with-icon>
        </div>
      </module-wrapper>

      <module-wrapper :animate="true">
        <div id="our-giving"> 
          <detail-grid :data="entry.ourGiving[0]">
            <template v-slot:intro>
              <title-with-icon class="intro" :data="entry.ourGiving[0]"></title-with-icon>
             </template>
            <template v-slot:sidebar>
              <div class="sidebar">
                <div class="outro-blurb" v-html="entry.ourGiving[0].outro"></div>
            
                <!-- <div class="form-trigger has-arrow" v-if="!showForm" @click="(showForm = true)">
                  Submit an expression of interest
                  <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
                </div> -->
            
                <!-- <form-helper v-if="showForm" id="EOIForm" :recaptcha="true">
                  <input type="hidden" name="form_id" value="2">
                  <input type="text" name="name" placeholder="Name">
                  <input type="text" name="phone" placeholder="Phone">
                  <input type="email" name="email" placeholder="Email">
                  <input type="text" name="company" placeholder="Organisation">
                  <div class="select-wrapper" :class="{'changed' : entity !== 'default'}">
                    <select name="entity" v-model="entity">
                      <option value="default" disabled selected>Type of entity</option>
                      <option value="Charitable Trust">Charitable Trust</option>
                      <option value="Incorporated Society">Incorporated Society</option>
                      <option value="Community Group">Community Group</option>
                      <option value="Unincorporated Group">Unincorporated Group</option>
                      <option value="Individual/Other">Individual/Other</option>
                    </select>
                  </div>
                  <div class="select-wrapper" :class="{'changed' : focus !== 'default'}">
                    <select name="focus" v-model="focus">
                      <option value="default" disabled selected>Primary focus area</option>
                      <option value="Education">Education</option>
                      <option value="Arts and Culture">Arts and Culture</option>
                      <option value="Well-being and Health">Well-being and Health</option>
                      <option value="Environment">Environment</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <label for="message">Message</label>
                  <textarea name="message"
                    placeholder="Please provide some commentary on your organisation, purpose and funding request"></textarea>
                </form-helper> -->
              </div>
            </template>
            
          </detail-grid>
        </div>
      </module-wrapper>
      
      <module-wrapper class="bg-2" :animate="true">
        <div id="our-impact">
          <title-with-icon :data="entry.ourImpact[0]"></title-with-icon>
          <impact-items :data="entry.ourImpact[0].items"></impact-items>
        </div>
      </module-wrapper>

      <module-wrapper :animate="true">
        <div id="about-us">
          <title-with-icon :data="entry.aboutUs[0]" :swap="true">
            <div class="img-wrapper is-cover three-two">
              <craft-image-helper :src="entry.aboutUs[0].image"></craft-image-helper>
            </div>
          </title-with-icon>
        </div>
      </module-wrapper>
      
      <news-teaser :data="this.$store.state.featuredNews"></news-teaser>
    </div>
  </div>
</template>

<script>
  import HeroHelper from "~/components/HeroHelper.vue";
  import IntroModule from "~/components/IntroModule.vue";
  import JumpMenu from "~/components/JumpMenu.vue";
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import TitleWithIcon from "~/components/TitleWithIcon.vue";
  import VideoHelper from "~/components/VideoHelper.vue";
  import DetailGrid from "~/components/DetailGrid.vue";
  import ImpactItems from "~/components/ImpactItems.vue";
  import NewsTeaser from "~/components/NewsTeaser.vue";
  import FormHelper from "~/components/FormHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { HeroHelper, IntroModule, JumpMenu, ModuleWrapper, TitleWithIcon, VideoHelper, DetailGrid, ImpactItems, NewsTeaser, FormHelper },
    data() {
      return {
        entry: Object,
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
        entity: 'default',
        focus: 'default',
        form_id: 1,
        showForm: false
      }
    },
    computed: {
      heroImage(){
        let heroImages = []
        let image = {heroImage: this.entry.heroImage, title: this.entry.heroImageTitle}
        heroImages.push(image)
        return heroImages
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "foundation", ) {
            id title slug 
            ...on foundation_foundation_Entry {
              heroImageTitle 
                        heroImage {
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
              introTitle: foundationTitle
              introBlurb: foundationBlurb
              ourFoundation{
                ...on ourFoundation_detail_BlockType{
                  title: foundationDetailTitle
                  description: foundationDetailDescription
                  image {
                     url,
                      title,
                      width,
                      height,
                      focalPoint,
                      mimeType,
                      s400: url @transform(width: 400),
                      s800: url @transform(width: 800),
                      s1200: url @transform(width: 1200),
                      s1600: url @transform(width: 1600),
                      s2000: url @transform(width: 2000),
                      s2400: url @transform(width: 2400),
                      s2800: url @transform(width: 2800),
                      s3200: url @transform(width: 3200)
                  }
                  video: videoLink
                }
              }
              ourGiving{
                ...on ourGiving_detail_BlockType{
                  title: ourGivingTitle
                  outro: ourGivingOutro
                  items: ourGivingItems{
                    ...on ourGivingItems_BlockType{
                      title: ourGivingItemTitle
                      description: ourGivingItemDescription
                      image: ourGivingItemImage{
                        url,
                        title,
                        width,
                        height,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200)
                      }
                      imageAlt: ourGivingItemImageAlt{
                                  url,
                                  title,
                                  width,
                                  height,
                                  focalPoint,
                                  mimeType,
                                  s400: url @transform(width: 400),
                                  s800: url @transform(width: 800),
                                  s1200: url @transform(width: 1200),
                                  s1600: url @transform(width: 1600),
                                  s2000: url @transform(width: 2000),
                                  s2400: url @transform(width: 2400),
                                  s2800: url @transform(width: 2800),
                                  s3200: url @transform(width: 3200)
                                }
                      ourGivingItemLink
                      metrics{
                        ...on metrics_metric_BlockType{
                          title: metricTitle
                          description: metricDescription
                        }
                      }
                    }
                  }
                }
              }
              ourImpact{
                ...on ourImpact_impact_BlockType{
                  title: impactTitle
                  description: impactDescription
                  items: impactItems{
                    ...on impactItems_BlockType{
                      title: impactItemTitle
                      description: impactItemDescription
                      image: impactItemImage{
                        url,
                        title,
                        width,
                        height,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200)
                      }
                      links: itemLinks{
                        ...on itemLinks_link_BlockType{
                          link: linkURL
                          linkTitle: linkLabel
                        }
                      }

                    }
                  }
                }
              }
              aboutUs{
                ...on aboutUs_detail_BlockType{
                  title: aboutUsTitle
                  description: aboutUsDescription
                  image: aboutUsImage{
                    url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  }
                  
                  link: optionLink{
                              ...on linkField_Link{
                                element { id slug ...on EntryInterface{typeHandle slug sectionHandle } }
                                url
                                customText
                              }
                            } 
                }
              }
            }
          }
        }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              // this.$store.commit('setLoaded', true)
              //console.log('GQL Data:')
              //console.log(data.entry)
              data.entry.ourGiving[0].items.forEach(function (value, index) {
                // console.log(value.title)
                value.id = this.$toKebab(value.title)
                // console.log(value)
              }.bind(this));
              // console.log(data.entry)
              this.entry = data.entry
              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
       convertLink(link) {
        let routeName = this.$craftVueRouteTransform(link.element.__typename,link.element.slug)
        let result = {
            name: link.element.slug
          }
        if (!routeName){
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (link.element.slug) {
            result.params = {
              slug: link.element.slug
            }
          }
          //console.log(result)
        }
        return result
      }
    },
  }
</script>