<template>
  <ul>
    <li><a :href="`tel:${$formatTel(this.$store.state.phone)}`">{{$formatTel(this.$store.state.phone)}}</a></li>
    <li><a :href="`mailto:${this.$store.state.email}`">{{this.$store.state.email}}</a></li>
  </ul>  
</template>


<script>

  export default {
    components: { },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>