<template>
  <header v-scroll-lock="overlay" :class="menuStatus">
    <div class="container">
      <router-link :to="{name: 'home'}" @click.native="homeClick()" class="logo">
        <inline-svg :src="$getImageSrc('gough-logo')"></inline-svg>
      </router-link>
      <nav :class="navClasses">
        <ul class="container">
          <li v-for="parent in menuData">
            <template v-if="parent.children">
              <div class="inner">
                <link-helper :to="parent.name"></link-helper>
                <div class="trigger" v-if="xsMode" :class="{active : curActiveHeight && curActive === parent.name}" @click="toggleChildren(parent.name)">
                  <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
                </div>
              </div>
              <ul v-if="curActive === parent.name || !xsMode" :ref="parent.name" :class="{active : curActive === parent.name}" :style="{height: curActiveHeight + 'px'}">
                <li v-for="child in parent.children" :class="{visible : showChildren}">
                  <link-helper :to="parent.name" :child="child"></link-helper>
                </li>
              </ul>
            </template>

            <link-helper v-else :to="parent.name" :class="{contact : parent.name === 'contact'}"></link-helper>
          </li>

        </ul>
      </nav>
      <div v-if="xsMode" @click="toggleOverlay" class="hamburger" :class="{'active' : overlay}"></div>
    </div>
    
  </header>
</template>


<script>
  import LinkHelper from '~/components/LinkHelper'
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { LinkHelper },
    data(){
      return {
        overlay: false,
        transition: false,
        curActive: false,
        curActiveHeight: 0,
        showChildren: false,
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      showNav() {
        return !this.xsMode || this.overlay
      },
      xsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm'
      },
      navClasses() {
        let result = []
        if(this.showNav) { 
          result.push('active') 
        }
        if(this.transition) { 
          result.push('transition') 
        }
        if(this.xsMode) { 
          result.push('xs-mode') 
        } else {
          result.push('not-xs-mode')
        }
        return result
      },
      menuData() {
        return this.$store.state.menuData
      },
      menuStatus() {
        if(this.overlay) {
          return ['pending','fixed']
        } else {
          return this.$store.state.menuStatus
        }
      }
    },
    methods: {
      toggleOverlay() {
        this.$updateCssVars();
        this.overlay = !this.overlay
        this.transition = true
        setTimeout(() => {
          this.transition = false
          this.curActive = false
        }, 350);
      },
      closeOverlay(fromBreakpoint) {
        this.overlay = false
        if(!fromBreakpoint) {
          this.transition = true
        }
        setTimeout(() => {
          this.transition = false
          this.curActive = false
        }, 350);
      },
      homeClick() {
        this.closeOverlay()
        if(this.$route.name === 'home') {
          VueScrollTo.scrollTo('body')
        }
      },
      toggleChildren(parent) {
        let ease = 500
        if(this.curActive === false) {
          // nothing open, open drawer
          this.curActive = parent
          this.$nextTick(()=>{
            this.curActiveHeight = this.$refs[parent][0].scrollHeight
            this.showChildren = true
          });
        } else {
          // something open
          if(this.curActive === parent) {
            // close current drawer
            this.curActiveHeight = 0
            this.showChildren = false
            setTimeout(() => {
              this.curActive = false
            }, ease);
          } else {
            // close current drawer
            this.curActiveHeight = 0
            this.showChildren = false
            // open new drawer
            setTimeout(() => {
              this.curActive = parent
              this.$nextTick(()=>{
                this.showChildren = true
                this.curActiveHeight = this.$refs[parent][0].scrollHeight
              });
            }, ease);
          }
        }
      }
    },
    watch: {
      breakpoint() {
        this.closeOverlay(true);
      },
      overlay() {
        EventBus.$emit('overlayStatus', this.overlay);
      }
    },
    mounted() {
      EventBus.$on('closeOverlay', (emit) => {
        this.closeOverlay();
      });

      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.closeOverlay()
        }
      });
    }
  }
</script>