<template>
  <div id="news" v-if="craftLoaded && featuredNewsLoaded">
    <module-wrapper class="container">
      <div class="news-inner">
        <h1>News</h1>
        <div class="filters">
          <span>FILTER</span>
          <div class="filter-wrapper" v-if="this.$store.state.newsYears.length > 1">
            <div class="current">{{ activeYearName }}</div>
            <select name="years" id="news-years" v-model="activeYear" >
              <option value="all">All Years</option>
              <option v-for="year in this.$store.state.newsYears" :value="$toKebab(year)">{{year}}</option>
            </select>
          </div>
          <div class="filter-wrapper">
            <div class="current">{{ activeCategoryName }}</div>
            <select name="categories" id="news-categories" v-model="activeCategory">
              <option value="all">All News</option>
              <option v-for="category in this.$store.state.newsCategories" :value="category.id" :name="category.title">{{category.title}}</option>
            </select>
          </div>
        </div>

        <article-grid>
          <article-teaser v-for="(article, index) in articles" :data="article" :style="{transitionDelay: 250 + (index * 125) + 'ms'}"></article-teaser>
        </article-grid>

        <p v-if="this.articlesLoaded && totalArticles == 0">Sorry, there are currently no articles for this filter set.</p>

        <div class="view-more has-arrow" @click="loadMore()" :class="{loading:loading}" v-if="loadedArticles < totalArticles">
          {{viewMore}}<inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
        </div>
      </div>
    </module-wrapper>
  </div>
</template>

<script>
  import gql from 'graphql-tag'
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import ArticleTeaser from "~/components/ArticleTeaser.vue";

  export default {
    components : { ModuleWrapper, ArticleGrid, ArticleTeaser },
    data() {
      return {
        articles: Array,
        articlesLoaded: false,
        total: 0,
        skipQuery: true,
        activeCategory: 'all',
        activeYear: 'all',
        batchSize: 12,
        loading: false,
        skipQuery: false,
      }
    },
    computed: {
      viewMore() {
        return (this.loading) ? 'Loading...' : 'View more news'
      },
      loadedArticles(){
        return this.articles.length
      },
      totalArticles(){
        return this.total
      },
      activeCategoryName() {
        if(this.activeCategory === 'all') {
          return 'All News'
        } else {
          return this.$store.state.newsCategories.find(category => category.id === this.activeCategory).title
        }
      },
      activeYearName() {
        if (this.activeYear === 'all') {
          return 'All Years'
        } else {
          return this.activeYear
        }
      },
    },
    apollo: {
      newsArticles: {
        query: gql`query variables($offset: Int!, $limit: Int!, $categoryId: [QueryArgument], $postDate: [String]){ 
                    newsArticles: entries(section: "article", 
                            orderBy: "postDate DESC",
                            offset: $offset,
                            limit: $limit,
                            relatedTo: $categoryId,
                            postDate: $postDate)
                      {
                        ...on article_default_Entry{
                         id title date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                          ... on article_default_Entry {
                            title slug category: articleCategory {
                              title
                            }
                            image: articleHeroImage {
                              url
                              title
                              width
                              height
                              focalPoint
                              mimeType
                              s400: url @transform(width: 400)
                              s800: url @transform(width: 800)
                              s1200: url @transform(width: 1200)
                              s1600: url @transform(width: 1600)
                              s2000: url @transform(width: 2000)
                              s2400: url @transform(width: 2400)
                              s2800: url @transform(width: 2800)
                              s3200: url @transform(width: 3200)
                            }
                          }
                      }
                    }
                    total: entryCount(section: "article",relatedTo: $categoryId,postDate: $postDate )
                  }`,
        fetchPolicy: 'no-cache',
        variables(){
          let offset = 0
          let limit = 0
          let categoriesVal = []
          let postDate = []

          if (this.activeCategory !== 'all')
            categoriesVal.push(this.activeCategory)

          if (this.activeYear !== 'all'){
            postDate.push("and")
            postDate.push(">= " + this.activeYear + "-01-01")
            postDate.push("<= " + this.activeYear + "-12-31")
          }

          if (this.articlesLoaded){
            offset = this.articles.length
            limit = this.batchSize
          }
          else{
            offset = 0
            limit = this.batchSize
          }
          return {
            offset: offset,
            limit: limit,
            categoryId : categoriesVal,
            postDate: postDate
          }
        },
        result ({ data, loading, networkStatus }) {
          if(data.newsArticles !== null) {
            if (!this.articlesLoaded){
              //console.log('refresh article set')
              this.articles = data.newsArticles
              this.articlesLoaded = true
              this.total = data.total
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
              this.$apollo.queries.newsArticles.skip = true
            }
            else{
              //console.log('extend article set')
              this.articles.push(...data.newsArticles)
              this.$apollo.queries.newsArticles.skip = true
            }
            this.$apollo.queries.newsArticles.skip = true
          }
        },
        skip() {
          return this.skipQuery
        }
      }
    },
    methods: {
      filterChanged(filter) {
        if(filter === 'category') {
          //console.log('Filter Changed... category is now ' + this.activeCategory)
          this.articlesLoaded = false
          this.articles = []
          this.total = 0
          this.$apollo.queries.newsArticles.skip = false
          this.$apollo.queries.newsArticles.refetch()
        } 
        else if (filter === 'year') {
          //console.log('Filter Changed... year is now ' + this.activeYear)
          this.articlesLoaded = false
          this.articles = []
          this.total = 0
          this.$apollo.queries.newsArticles.skip = false
          this.$apollo.queries.newsArticles.refetch()
        }
      },
      loadMore() {
        //console.log('Loading more...')
        this.loading = true
        // setTimeout(()=>{  // Shouldnt need this now we have skipQuery in play etc but will leave commented in case
        //   this.loading = false
        // },2000);
        this.$apollo.queries.newsArticles.skip = false
        this.$apollo.queries.newsArticles.refetch()
      }
    },
    watch: {
      activeCategory() {
        this.filterChanged('category')
      },
      activeYear() {
        this.filterChanged('year')
      }
    },
    created(){
      this.$apollo.queries.newsArticles.skip = false
      this.$apollo.queries.newsArticles.refetch()
    }
  }
</script>