<template>
  <div id="home" v-if="craftLoaded && featuredNewsLoaded">

    <hero-helper class="full" :data="entry.heroImages"></hero-helper>

    <div class="container">

      <module-wrapper class="inset bg-2">
        <anime-icon-alt :data="entry"></anime-icon-alt>
      </module-wrapper>

      <!-- <module-wrapper class="inset">
        <div class="featured" :key="breakpoint">
          <div class="title">Featured Activities & Partners</div>
          <carousel-helper :options="carouselOptions" :custom-nav="true">
            <swiper-slide v-for="(slide, index) in carouselData" class="slide" :key="index">
              <div class="slide-inner is-cover three-two">
                <link-helper :to="carouselData[curSlide].link.to" :child="carouselData[curSlide].link.child"
                  :self="carouselData[curSlide].link.self">
                  <div class="slide-title inset">{{slide.title}}</div>
                  <craft-image-helper :src="slide.image"></craft-image-helper>
                </link-helper>
              </div>
            </swiper-slide>
          </carousel-helper>
          <link-helper :to="carouselData[curSlide].link.to" :child="carouselData[curSlide].link.child"
            :self="carouselData[curSlide].link.self" class="more-link has-arrow">
            Find out more <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
          </link-helper>
        </div>
      </module-wrapper> -->
      
      <!-- <template v-for="(block, index) in entry.imageGridContent">
        <module-wrapper :class="{'bg-2' : !isOdd(index)}">
          <image-grid :data="block" :class="{'alt' : isOdd(index)}"></image-grid>
        </module-wrapper>
      </template> -->

      <!-- <news-teaser :data="this.$store.state.featuredNews"></news-teaser> -->

    </div>
  </div>
</template>

<script>
  import { EventBus } from '/event-bus.js';
  import CarouselHelper from "~/components/CarouselHelper.vue";
  import { SwiperSlide } from 'vue-awesome-swiper'
  import LinkHelper from "~/components/LinkHelper.vue";
  import HeroHelper from "~/components/HeroHelper.vue";
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import AnimeIconAlt from "~/components/AnimeIconAlt.vue";
  import ImageGrid from "~/components/ImageGrid.vue";
  import NewsTeaser from "~/components/NewsTeaser.vue";
  import gql from 'graphql-tag'

  export default {
    components : { CarouselHelper, SwiperSlide, LinkHelper, HeroHelper, ModuleWrapper, AnimeIconAlt, ImageGrid, NewsTeaser },
    data() {
      return {
        entry: Object,
        curSlide: 0,
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 20,
          speed: 1000,
          preventClicks: false,
          preventClicksPropagation: false,
          // slidesPerView: 'auto',
          // autoplay: 5000,
          breakpoints: {
            768: {
              slidesPerView: 'auto',
              spaceBetween: 50,
            },
            992: {
              slidesPerView: 'auto',
              spaceBetween: 75,
            },
            1200: {
              slidesPerView: 'auto',
              spaceBetween: 100,
            },
            1440: {
              slidesPerView: 'auto',
              spaceBetween: 125,
            },
          }
        },
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      xsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm'
      },
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "home") {
            id title slug 
            ...on home_home_Entry {
              heroImages {
                ...on heroImages_heroImage_BlockType{
                  title: heroImageTitle 
                  heroImage {
                    url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  }
                }
              }
              familyOfficeImage{
                url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
              }
              investmentsImage{
                url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
              }
              foundationImage{
                url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
              }
              animationTitle animationDescription
              imageGridContent {
                ...on imageGridContent_block_BlockType{
                  images: ContentBlockImages {
                      url
                      title
                      width
                      height
                      focalPoint
                      mimeType
                      s400: url @transform(width: 400)
                      s800: url @transform(width: 800)
                      s1200: url @transform(width: 1200)
                      s1600: url @transform(width: 1600)
                      s2000: url @transform(width: 2000)
                      s2400: url @transform(width: 2400)
                      s2800: url @transform(width: 2800)
                      s3200: url @transform(width: 3200)
                  }
                  title: contentBlockTitle content: contentBlockDescription
                  linkItem: contentBlockLink {
                    id title url slug
                  }
                  linkLabel: contentBlockLinkLabel
                }
              }
            }
          }
          carouselItemsInvestments: entry(section:"investments"){
            ...on investments_investments_Entry{
              id
              directInvestments{
                ...on directInvestments_directInvestment_BlockType{
                  title: directInvestmentTitle
                  description
                  items{
                    ...on items_BlockType{
                      title: itemTitle
                      description: itemDescription
                      metrics{
                        ...on metrics_metric_BlockType{
                          title: metricTitle
                          description: metricDescription
                        }
                      }
                      image: itemImage{
                        url,
                        title,
                        width,
                        height,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200)
                      }
                      link: itemLink
                      carousel: showOnHomePageFeaturedCarousel
                    }
                  }
                }
              }
              managedFunds: managedFundsInvestments{
                ...on managedFundsInvestments_managedFunds_BlockType{
                  title: managedFundsTitle
                  description: managedFundsDescription
                  image: managedFundsImage{
                    url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  }
                  carousel: managedFundsShowOnHomePage
                  logo: managedFundsLogo{
                    url,
                    title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  }
                  linkTitle
                  overlay{
                    ...on overlay_BlockType{
                      title: overlayTitle
                      content: overlayContent
                      image: overlayImage{
                        url,
                        title,
                        width,
                        height,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200) 
                      }
                      link: overlayLink
                      linkTitle: overlayLinkTitle
                    }
                  }
                }
              }
            }
          }
          carouselItemsFoundation: entry(section:"foundation"){
            id
            ...on foundation_foundation_Entry{
              foundationInvestments: ourImpact {
                ...on ourImpact_impact_BlockType{
                  impactItems{
                    ...on impactItems_BlockType{
                      title: impactItemTitle
                      image: impactItemImage{
                          url,
                            title,
                            width,
                            height,
                            focalPoint,
                            mimeType,
                            s400: url @transform(width: 400),
                            s800: url @transform(width: 800),
                            s1200: url @transform(width: 1200),
                            s1600: url @transform(width: 1600),
                            s2000: url @transform(width: 2000),
                            s2400: url @transform(width: 2400),
                            s2800: url @transform(width: 2800),
                            s3200: url @transform(width: 3200)
                      }
                      carousel: showOnHomePage
                    }
                  }
                }
              }
            }
          }   
          
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              //console.log('GQL Data:')
              //console.log(data)
              // console.log(data.carouselItemsInvestments)
              // console.log(data.carouselItemsFoundation)
              let carouselData = this.prepareCarouselData(data)
              this.carouselData = carouselData
              //console.log('Carousel Data:')
              //console.log(carouselData)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
      prepareCarouselData(data){
        let carouselData = []
        // Check each set 
        if (data.carouselItemsInvestments.directInvestments[0].items.length){
          data.carouselItemsInvestments.directInvestments[0].items.forEach(function (value, index) {
            if (value.carousel){
              let link = {to: 'investments', child: 'direct-investments', self: this.$toKebab(value.title)}
              value.link = link
              carouselData.push(value)
            }
          }.bind(this));
        }
        if (data.carouselItemsInvestments.managedFunds.length){
          data.carouselItemsInvestments.managedFunds.forEach(function (value, index) {
            if (value.carousel){
              let link = {to: 'investments', child: 'alvarium'}
              value.link = link
              carouselData.push(value)
            }
          }.bind(this));
        }
        if (data.carouselItemsFoundation.foundationInvestments[0].impactItems.length){
          data.carouselItemsFoundation.foundationInvestments[0].impactItems.forEach(function (value, index) {
            if (value.carousel){
              let link = {to: 'foundation', child: this.$toKebab(value.title)}
              value.link = link
              carouselData.push(value)
            }
          }.bind(this));
        }
        carouselData.sort(function(a, b) { 
          return b.id - a.id;
        });
        return carouselData
      },
      isOdd(index){
        return index % 2
      }
    },
    mounted() {
      EventBus.$on('slideChange', (emit) => {
        this.curSlide = emit
      });
    },
  }
</script>