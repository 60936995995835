
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'
import ImageHelper from './components/CraftImageHelper.vue'

import App from './components/App.vue'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'
import Anime from 'animejs';
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)
Vue.use(VueApollo)

Vue.component('craft-image-helper', ImageHelper)

Vue.use(VueReCaptcha, { siteKey: '6Le-psMhAAAAAOuTKVpeL2NcFDcM92NBomeBZFil',loaderOptions: {
    autoHideBadge: true
  } })

// create a global variable for anime
Object.defineProperty(Vue.prototype, '$anime', { value: Anime });


let axiosBaseDomain = process.env.BASE_URL
let routerImport = Router
const host = window.location.host;
axiosBaseDomain = process.env.BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${axiosBaseDomain}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})
const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

router.beforeEach((to, from, next) => {
  // unload craft data on page changes, not hashß
  if((!to.hash && to.name !== from.name) || (to.hash && to.name !== from.name)) { 
    store.commit('setCraftLoaded', false)
    store.commit('setCraftPageData', null)
  }
  next();
});

Vue.mixin({
  computed: {
   craftLoaded: {
     get() {
       return this.$store.state.craftLoaded
     }
    },
    featuredNewsLoaded: {
     get() {
       return this.$store.state.featuredNewsLoaded
     }
    },
  }
})

Vue.use(VueGtag, {
  config: { id: "UA-35394619-2" }
}, router);

new Vue({
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');