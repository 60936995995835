<template>
  <div class="module-wrapper"
       :class="classes"
       ref="thisWrapper"
       v-observe-visibility="{callback: visibilityChanged, intersection: {rootMargin:'-25%'}}">
       <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      jumpMenu: Boolean,
      news: Boolean,
      loop: Boolean,
      animate: Boolean
    },
    data() {
      return {
        active: false
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      classes(){
        let result = []
        if(this.jumpMenu) {
          result.push('is-jump-menu')
          if(this.isXs) {
            result.push('bg-2')
          }
        }
        if(this.news) {
          result.push('is-news')
        }
        if(this.animate) {
          result.push('is-animated')
        }
        if(this.active) {
          result.push('active')
        }
        return result
      }
    },
    methods: {
      visibilityChanged (isVisible, entry) {
        if(this.loop) {
          if(isVisible) {
            this.active = true
            this.$refs['thisWrapper'].children[0].classList.add('active')
          } else {
            this.active = false
            this.$refs['thisWrapper'].children[0].classList.remove('active')
          }
        } else {
          if(isVisible) {
            this.active = true
            this.$refs['thisWrapper'].children[0].classList.add('active')
          }
        }
      }
    },
    mounted() {
    },
  }
</script>