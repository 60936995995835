<template>
  <div id="error" class="container" v-if="featuredNewsLoaded">
    <div class="inner">
      <h1>Error.</h1>
      <h4>We can't find the page you are looking for.</h4>
     
      <ul class="links">
        <li>Here are some helpful links instead:</li>
        <li><link-helper :to="'home'"></link-helper></li>
        <li v-for="link in $store.state.menuData">
          <link-helper :to="link.name"></link-helper>
        </li>
      </ul>
      <div class="detail">
        <div class="detail-inner">
          <span><strong>Gough Investments</strong></span>
          <address-details></address-details>
          <contact-details></contact-details>
        </div>
        <inline-svg :src="$getImageSrc('gough-icon')"></inline-svg>
      </div>
    </div>
  </div>
</template>


<script>
  import AddressDetails from '~/components/AddressDetails.vue'
  import ContactDetails from '~/components/ContactDetails.vue'
  import LinkHelper from '~/components/LinkHelper'

  export default {
    components: { AddressDetails, ContactDetails, LinkHelper },
    data(){
      return {
      }
    },
    computed: {
      page() {
        return this.$route.name
      }
    }
  }
</script>