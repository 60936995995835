<template>
  <div class="intro-module">
    <h3>{{data.introTitle}}</h3>
    <inline-svg v-if="logo" :src="$getImageSrc(logo)" class="icon col sm-span-2-row"></inline-svg>
    <div class="blurb col span-2-col sm-span-1-col" v-html="data.introBlurb"></div>
  </div>
</template>

<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      logo:{
        type: String,
        required: false
      }
    },
    data() {
      return {
      } 
    },
    computed: {
      hasSlot() {
        return Object.keys(this.$slots).length
      }
    },
    methods: {
    },
  }
</script>