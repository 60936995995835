<template>
  <div id="jump-menu">
    <link-helper v-for="(child,index) in children" :to="parent" :child="child">
      <ul>
        <li v-if="childIcon(child)">
          <inline-svg class="icon" :src="childIcon(child)"></inline-svg>
        </li>
        <li v-else>{{index + 1}}</li>
        <li>
          <span v-html="lb(child)"></span>
          <!-- <inline-svg class="arrow" :src="$getImageSrc('big-arrow')"></inline-svg> -->
        </li>
      </ul>
    </link-helper>
  </div>
</template>


<script>
  import LinkHelper from "~/components/LinkHelper.vue";

  export default {
    components: { LinkHelper },
    data(){
      return {
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      parent() {
        return this.$route.name
      },
      children() {
        return this.$store.state.menuData[this.parent].children
      },
    },
    methods: {
      childIcon(child) {
        let result = this.$getImageSrc(child)
        return (result) ? result : false
      },
      lb(child) {
        return (this.isXs) ? this.$toTitle(child) : this.$toTitle(child).replace(' ','<br/>')
      }
    }
  }
</script>