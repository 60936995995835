<template>
  <footer v-if="craftLoaded && featuredNewsLoaded">
		<div class="outer container grid gap-30">
      
      <div class="inner col grid sm-col-2">

        <address-details class="col xs-span-2-col sm-span-1-col"></address-details>
        
        <contact-details></contact-details>

        <ul>
          <li v-if="this.$store.state.linkedInURL">Follow us on <a :href="this.$store.state.linkedInURL" target="_blank">LinkedIn</a></li>
        </ul>

        <ul class="col xs-span-2-col sm-span-1-col">
          <li><router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link></li>
          <li class="copyright">Copyright ©{{year}} Gough Investments</li>
        </ul>
      </div>
      
      <ul class="top">
        <li @click="top()">Top</li>
      </ul>
    </div>
  </footer>
</template>


<script>
  import AddressDetails from '~/components/AddressDetails.vue'
  import ContactDetails from '~/components/ContactDetails.vue'
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { AddressDetails, ContactDetails },
    data(){
      return {
      }
    },
    computed: {
      year() {
        return new Date().getFullYear();
      }
    },
    methods: {
      top() {
        VueScrollTo.scrollTo('html')
      }
    }
  }
</script>