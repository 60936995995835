<template>
  <main :style="{marginTop:offset}">
    <overlay v-if="!errorPage && overlayData"></overlay>
    <app-menu v-if="!errorPage"></app-menu>
    <div class="content">
      <router-view :key="route.name"></router-view>
    </div>
    <app-footer v-if="!errorPage"></app-footer>
    <!-- <alert-bar v-if="!errorPage"></alert-bar> -->
  </main>
</template>

<script>
  import Overlay from '~/components/Overlay.vue'
  import AppMenu from '~/components/AppMenu.vue'
  import AppFooter from '~/components/AppFooter.vue'
  import AlertBar from '~/components/AlertBar.vue'
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { Overlay, AppMenu, AppFooter, AlertBar },
    data() {
      return {
        offset: 0,
        refresh: true,
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      route() {
        return this.$route
      },
      errorPage(){
        return this.route.name === 'error'
      },
      overlayData(){
        return this.$store.state.overlayData
      },
      menuOffset() {
        let xsMenu = 75 // see scss variables
        let mdMenu = 90 // see scss variables
        let breakpoint = this.$store.state.breakpoint

        return (breakpoint === 'xs' || breakpoint === 'sm') ? xsMenu : mdMenu
      },
      moduleOffset() {
        let breakpoint = this.$store.state.breakpoint
        let offset = 40

        if(breakpoint === 'md') { offset = 60 }
        if(breakpoint === 'lg') { offset = 80 }
        if(breakpoint === 'xl' || breakpoint === 'xxl') { offset = 100 }
        
        return offset
      }
    },
    methods: {
      handleMenuScroll(source) {
        let scrollPos = window.scrollY
        let breakpoint = this.$store.state.breakpoint
        let slideTrigger = document.documentElement.clientHeight / 2
        let menuSize = this.menuOffset
       
        if(source === 'overlay') { 
          this.offset = menuSize + 'px'
          return false
        }

        if(scrollPos < menuSize) {
          this.$store.commit('setMenuStatus',null)
          this.offset = 0
        } else if(scrollPos > menuSize && scrollPos < slideTrigger) {
          this.$store.commit('setMenuStatus','pending')
          this.offset = menuSize + 'px'
        } else {
          this.$store.commit('setMenuStatus',['pending','fixed'])
        }
      },
      handlePageScroll(timeout) {
        this.$store.commit('setOverlayData',null)

        if(this.route.params.self) {
          this.$store.commit('setSelf',this.route.params.self)
        } else {
          this.$store.commit('setSelf',null)
        }

        if(this.route.hash === '') {
          this.$nextTick(()=>{
            // console.log('scrolling to top')
            this.$store.commit('setChild',null)
          });
        } else {
          this.$nextTick(()=>{
            // console.log('scrolling to ' + this.route.hash)
            this.$store.commit('setChild',this.route.hash)

            const interval = setInterval(() => {
              if (this.$store.state.craftLoaded && this.$store.state.craftPageData) {
                setTimeout(() => {
                  VueScrollTo.scrollTo(this.route.hash, 1000, { 
                    easing: [0.45, 0, 0.55, 1],
                    offset: -(this.menuOffset + this.moduleOffset)
                  });
                }, timeout);
                clearInterval(interval)
              }
            }, 50)
            
          });
        }
      }
    },
    created() {
      this.$store.commit('setNewLoad',true)
      window.addEventListener("scroll", this.handleMenuScroll);
    },
    mounted() {
      EventBus.$on('overlayStatus', (emit) => {
        if(emit) {
          this.handleMenuScroll('overlay')
        } else {
          this.handleMenuScroll()
        }
      });

      EventBus.$on('routerEvent', (emit) => {
        if(emit.scroll) {
          this.handlePageScroll(emit.timeout);
        }
        this.$store.commit('setNewLoad',false)
      });
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleMenuScroll);
    },
  }
</script>
