<template>
  <div class="article-grid">
    <slot></slot>
  </div>
</template>

<script>
  
  export default {
    props: {
      data: {
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    }
  }
</script>