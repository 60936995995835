<template>
  <router-link :to="{name: to, hash: hash, params: {self: self}}" @click.native="click()">
    <slot>{{title}}</slot>
  </router-link>
</template>


<script>
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');

  export default {
    props: {
      to: String,
      child: String,
      self: String
    },
    data(){
      return {
        prevHash: null
      }
    },
    computed: {
      title() {
        return this.$toTitle(this.child || this.to)
      },
      hash() {
        return (this.child) ? '#' + this.child : ''
      },
    },
    methods: {
      click() {
        EventBus.$emit('closeOverlay', true);
        if(this.$route.name === this.to && this.$route.hash === '') {
          VueScrollTo.scrollTo('body')
        }
      }
    },
  }
</script>