<!-- https://animejs.com/documentation -->
<!-- https://tobiasahlin.com/moving-letters/ -->

<template>
  <div class="anime-text" :class="uid" v-html="html"></div>
</template>

<script>

  export default {
    props: {
      text: {
        type: String,
        required: true
      },
      duration: [Number, Boolean],
      introDuration: Number,
      introDelay: Number,
      outroDuration: Number,
    },
    data() {
      return {
        uid: 'anime-text-' + Math.ceil(Date.now() + Math.random())
      }
    },
    computed: {
      html() {
        let text = this.text.split(' ')

        text.forEach(function (value, index) {
          text[index] = value.replace(/\S/g, "<span class='letter'>$&</span>");
          text[index] = '<span class="word">' + text[index] + '</span>'
        });

        return text.join(' ')
      }
    },
    methods: {
      anime() {
        let charOffset = 10
        let targets = `.${this.uid} .letter`

        let textLength = this.text.split(' ').join('').length
        let textOffset = textLength * charOffset

        let introDuration = this.introDuration || 2000
        let introDelay = this.introDelay || 500
        let timeline = this.$anime.timeline({loop: false})
          
        // intro
        timeline.add({
          targets: targets,
          translateY: [10,0],
          translateZ: 0,
          opacity: [0,1],
          easing: "easeOutExpo",
          duration: introDuration,
          delay: (el, i) => introDelay + charOffset * i,
        })

        // outro

        if(this.duration) {
          let duration = this.duration + 1000 // Scss transition time - 500ms for margin of error
          let expectedIntro = introDuration + introDelay + textOffset
          let outroDuration = this.outroDuration || 1500
          let outroDelay = duration - (expectedIntro + outroDuration + textOffset)

          timeline.add({
            targets: targets,
            translateY: [0,0],
            translateZ: 0,
            opacity: [1,0],
            easing: "easeOutExpo",
            duration: outroDuration,
            delay: (el, i) => outroDelay + charOffset * i,
          });
        }
      }
    },
    mounted() {
      this.anime()
    },
    watch: {
      text() {
        this.$nextTick(() => {
          this.anime()
        });
      }
    }
  }
</script>