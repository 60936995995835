<template>
  <router-link class="article-teaser" :to="{name: 'article', params: {slug: article.slug}}">
    <ul>
      <li class="inner-wrapper">
        <ul class="inner">
          <li>{{article.category[0].title}}</li>
          <li>{{article.title}}</li>
          <li>{{article.date}}</li>
        </ul>
      </li>
      <li class="img-wrapper is-cover one-two">
        <craft-image-helper :src="article.image"></craft-image-helper>
      </li>
      <li class="arrow">
        <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
      </li>
    </ul>
  </router-link>
</template>

<script>
  import LinkHelper from "~/components/LinkHelper.vue";
  
  export default {
    components : { LinkHelper },
    props: {
      data: {
        type: Object, 
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
      article() {
        return this.data
      },
    },
    methods: {
    },
    mounted() {
    }
  }
</script>