<template>
  <div id="article" v-if="craftLoaded && featuredNewsLoaded">
    <module-wrapper class="container">
      <div class="article-inner">
        <router-link :to="{name: 'news'}" class="back has-arrow">
          <inline-svg :src="$getImageSrc('big-arrow')"></inline-svg>
          Back to all news
        </router-link>
        <ul class="details">
          <li>{{entry.category[0].title}}</li>
          <li class="date"> / {{entry.date}}</li>
        </ul>
        <h1>{{entry.title}}</h1>
        <div class="content-wrapper">
          <div class="img-wrapper is-cover three-two">
            <craft-image-helper :src="entry.image"></craft-image-helper>
          </div>
          <div class="copy wysiwyg" v-html="entry.copy"></div>
          <next-prev-article :id="entry.id"></next-prev-article>
        </div>
      </div>
    </module-wrapper>
  </div>
</template>

<script>
  import gql from 'graphql-tag'
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import NextPrevArticle from "~/components/NextPrevArticle.vue";

  export default {
    components : { ModuleWrapper, NextPrevArticle },
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      entry: {
        query: gql` query variables($slug: [String!]){
          entry (slug: $slug){
            ...on article_default_Entry{
              id title date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                  copy: articleCopy slug category: articleCategory {
                  title
                }
                image: articleHeroImage {
                  url
                  title
                  width
                  height
                  focalPoint
                  mimeType
                  s400: url @transform(width: 400)
                  s800: url @transform(width: 800)
                  s1200: url @transform(width: 1200)
                  s1600: url @transform(width: 1600)
                  s2000: url @transform(width: 2000)
                  s2400: url @transform(width: 2400)
                  s2800: url @transform(width: 2800)
                  s3200: url @transform(width: 3200)
                }
              }
            }
          }`,
        variables() {      
          return {slug: this.$route.params.slug}
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              // this.$store.commit('setLoaded', true)
              //console.log('GQL Data:')
              //console.log(data.entry)
              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setPageTitle', data.entry.title)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      },
    },
    computed: {
    },
    methods: {
    }
  }
</script>