<template>
  <div id="family-office" v-if="craftLoaded && featuredNewsLoaded">

    <hero-helper :data="heroImage"></hero-helper>

    <div class="container">

      <module-wrapper>
        <intro-module :data="entry" :logo="`gough-family-office`"></intro-module>
      </module-wrapper>

      <module-wrapper class="bg-2" :animate="true">
        <div class="coat-of-arms">
          <div class="intro">
            <h2>{{entry.coatOfArms[0].title}}</h2>
            <div class="description" v-html="entry.coatOfArms[0].description"></div>
            <img :src="$getImageSrc('gough-icon')">
          </div>
          <!-- <div class="tenets">
                  <ul v-for="tenet in entry.coatOfArms[0].tenets">
                    <li class="img-wrapper is-cover three-two">
                      <craft-image-helper :src="tenet.image"></craft-image-helper>
                      <div class="inner">
                        <img :src="$getImageSrc('gough-icon')">
                        <span class="title">{{tenet.title}}</h3>
                      </div>
                    </li>
                    <li v-html="tenet.description"></li>
                  </ul> 
                </div> -->
        </div>
      </module-wrapper>
      
      <!-- <module-wrapper :jump-menu="true">
        <div id="jump-menu" class="three-pillars">
          <ul v-for="(pillar, index) in entry.threePillars">
            <li>{{index+1}}</li>
            <li ref="pillar-title" :style="{height:pillarHeight}">{{pillar.title}}</li>
            <li>{{pillar.blurb}}</li>
          </ul>
        </div>
      </module-wrapper> -->
      

      <module-wrapper :animate="true">
        <people-grid :data="entry.people"></people-grid>
      </module-wrapper>
      

      
      
      
      <news-teaser :data="this.$store.state.featuredNews"></news-teaser>
    </div>
  </div>
</template>

<script>
  import HeroHelper from "~/components/HeroHelper.vue";
  import IntroModule from "~/components/IntroModule.vue";
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import PeopleGrid from "~/components/PeopleGrid.vue";
  import NewsTeaser from "~/components/NewsTeaser.vue";
  import gql from 'graphql-tag'

  export default {
    components : { HeroHelper, IntroModule, ModuleWrapper, PeopleGrid, NewsTeaser },
    data() {
      return {
        pillarHeight: 'auto',
        entry: Object,
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isXs() {
        return this.breakpoint === 'xs'
      },
      heroImage(){
        let heroImages = []
        let image = {heroImage: this.entry.heroImage, title: this.entry.heroImageTitle}
        heroImages.push(image)
        return heroImages
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "familyOffice", ) {
                     id title slug 
                      ...on familyOffice_familyOffice_Entry {
                        heroImageTitle 
                        heroImage {
                          url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                        }
                        introTitle: familyTitle
                        introBlurb: familyBlurb 
                        threePillars{
                          ...on threePillars_pillar_BlockType{
                            title: pillarTitle blurb: pillarBlurb
                          }
                        }
                        people{
                          ...on people_profile_BlockType{
                            name: profileName
                            title: profileTitle
                            category: profileRole {
                             id title
                            }
                            qualification: profileQualification
                            formalImage {
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                            casualImage {
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                            content: profileContent
                            linkedinUrl
                          }
                        }
                        coatOfArms{
                          ...on coatOfArms_coatOfArms_BlockType{
                            title: coatOfArmsTitle
                            description: coatOfArmsDescription
                          }
                        }
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              // this.$store.commit('setLoaded', true)
              //console.log('GQL Data:')
              //console.log(data.entry)
              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
      // getPillarHeight() {
      //   this.pillarHeight = 'auto'
      //   if(!this.isXs) {
      //     let result = 0
      //     let refs = this.$refs['pillar-title']

      //     refs.forEach(function (value, index) {
      //       if(refs[index].clientHeight > result) {
      //         result = refs[index].clientHeight
      //       }
      //     });
      //     this.pillarHeight = result + 'px'
      //   }
      // },
    },
    watch: {
      breakpoint() {
        // this.getPillarHeight()
      }
    },
    mounted() {
      const interval = setInterval(() => {
        if (this.$refs['pillar-title']) {
          this.getPillarHeight();
          clearInterval(interval)
        }
      }, 50)
    }
  }
</script>