<template>
  <div id="privacy-policy" v-if="craftLoaded && featuredNewsLoaded">
    <module-wrapper class="container">
      <div class="inner">
        <h1>Privacy Policy</h1>
        <div class="wysiwyg" v-html="entry.content">
        </div>
      </div>
    </module-wrapper>
  </div>
</template>

<script>
  import ModuleWrapper from "~/components/ModuleWrapper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ModuleWrapper },
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "privacyPolicy", ) {
                      id title slug 
                      ...on privacyPolicy_privacyPolicy_Entry {
                        content: privacyContent
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              //console.log('GQL Data:')
              //console.log(data.entry)
              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>