<template>
    <form v-on:submit.prevent="submitForm" :class="state">
        <slot></slot>

        <small v-if="recaptcha" class="recaptcha-byline">This site is protected by reCAPTCHA and the <br v-if="!$is('xs')">Google 
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>

        <button type="submit" class="has-arrow">
          {{submitValue}}
          <inline-svg v-if="state !== 'success'" :src="$getImageSrc('big-arrow')"></inline-svg>
        </button>
        
        <ul class="validation" v-if="Object.keys(errors).length">
          <li v-if="errors.length > 1">Errors.</li>
          <li v-else>Error.</li>
          <li v-for="error in errors">{{ error[0] }}</li>
        </ul>
    </form>
</template>

<script>
  import { load } from 'recaptcha-v3'

  export default {
    props: {
      submit: {
        type: String,
        default: 'Submit'
      },
      recaptcha: {
        type: Boolean,
        default: true
      },
      extra: {
        type: Object,
        default() {
          return {}
        }
      },
      showSubmit: {
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
        endpoint: '/wheelform/message/send',
        errors: false,
        state: null,
        recaptchaToken:null,
        response: ''
      }
    },
    computed: {
      submitValue() {
        if (this.state === 'processing') {
          return 'Processing...'
        } else if (this.state === 'success') {
          return 'Thanks, We\'ll be in touch.'
        } else {
          return this.submit
        }
      }
    },
    methods: {
      fetchCSRF(){
        this.axios.get('/csrf-token').then(res => {
          // console.log(res)
          this.$store.commit('setCSRFTokenName', res.data.csrfTokenName)
          this.$store.commit('setCSRFToken', res.data.csrfToken)
          // console.log('CSRF retrieve...')
          // console.log(res.data)
        }).catch(error => {
          // if(error.response.status === 500) this.$router.push({name: 'error', params: {status: error.response.status}})
          console.log(error)
        });
      },
      serverResponse(response){
        if (response.data.success){
          this.$emit('success', response.message)
          this.state = 'success'
        }
        else{
          this.state = null
          this.errors = response.data.errors
        }
      },
      serverError(response){
        // console.log(response)
        this.state = null
        //this.errors.push('There was a problem submitting your form. Please try again later')
        this.errors = response.data.errors
      },
      submitForm: function(e) {
        
        const recaptcha = this.$recaptchaInstance
        // console.log(recaptcha)
        // // Execute reCAPTCHA with action "login".
        load('6Le-psMhAAAAAOuTKVpeL2NcFDcM92NBomeBZFil').then((recaptcha) => {
          recaptcha.execute('test').then((token) => {
              // console.log(token) // Will print the token
              this.recaptchaToken = token

              this.state = 'processing'
              this.errors = false
              let routeName = this.$route.name
              const params = new URLSearchParams({ foo: 'bar' });
              params.append('action', '/wheelform/message/send');

              let form = document.getElementById('contactForm')
              if (form === null)
                form = document.getElementById('EOIForm')
              
              for (const el of form.elements) {
                if(!el.name || el.type === 'submit') continue;
                  params.append(el.name,el.value)
              }
              
              params.append('g-recaptcha-response', this.recaptchaToken);
              params.append(this.$store.state.csrfTokenName, this.$store.state.csrfToken);
              this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

              var optionAxios = {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
              return new Promise((serverResponse, serverError) => {
                this.axios.post('/wheelform/message/send', params, optionAxios)
                  .then((response) => this.serverResponse(response))
                  .catch((err) => this.serverError(err));
              });
            })
        })
      },
    },
    created(){
      this.fetchCSRF();
    }
  }
</script>