<template>
  <div class="detail-grid" :key="$store.state.self">

    <slot name="intro"></slot>
    
    <slot name="sidebar"></slot>
    
    <div class="teaser-wrapper">
      
      <div class="teaser" 
           v-for="(item,index) in data.items" 
           @click="selectProject(item.id)" 
           :class="{active: isActive(item.id)}">
        <div class="img-wrapper is-cover three-two">
          <craft-image-helper :src="item.image"></craft-image-helper>
        </div>
        <span>{{item.title}}</span>
      </div>

      <project-detail 
        :id="uid"
        class="col span-2-col"
        :data="activeProject" 
        :style="detailStyles"
        ref="detail">
      </project-detail>

      <slot name="outro"></slot>

    </div>
  </div>
</template>


<script>
  import { EventBus } from '/event-bus.js';
  import ProjectDetail from '~/components/DetailGrid-ProjectDetail.vue'


  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    components: { ProjectDetail },
    data(){
      return {
        uid: 'uid-' + (Math.trunc(Date.now() + Math.random())),
        activeProject: null,
        detailStyles: {
          height: '0px',
          opacity: 0,
          gridRow: false,
          transitionDuration: '500ms',
          transitionDelay: '0ms'
        }
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint;
      },
      xsMode() {
        let breakpoint = this.$store.state.breakpoint;
        return breakpoint === 'xs' || breakpoint === 'sm'
      },
    },
    methods: {
      selectProject(id) {
        let timeout = parseInt(this.detailStyles.transitionDuration)

        if(this.activeProject && this.activeProject.id === id) {
          // console.log('collapsing current')
          this.setStyles('hide')
          setTimeout(() => {
            this.activeProject = null
          }, timeout);
        } else {
          if(this.activeProject) {
            // console.log('collapsing current, loading new')
            this.setStyles('hide')
          } else {
            // console.log('loading new')
            timeout = 0
          }
          setTimeout(() => {
            this.setActive(id)
            this.$nextTick(()=>{
              this.calcPosition();
              this.setStyles('show')

              let offset = -93
              if(!this.xsMode) {
                offset = -108
              }
              
              this.$nextTick(() => {
                this.$scrollTo(`#${this.uid}`, 500, {
                  easing: [0.45, 0, 0.55, 1],
                  offset: offset
                });
              });
              
            })
          }, timeout);
        }
      },
      isActive(id) {
        return (this.activeProject && this.activeProject.id === id)
      },
      setActive(id) {
        let self = this
        this.data.items.forEach(function (value,index) {
          if(value.id === id) {
            self.activeProject = value
            self.activeProject.index = index
          }
        });
      },
      setStyles(mode) {
        if(mode === 'show') {
          this.detailStyles.transitionDelay = '125ms'
          this.detailStyles.height = this.$refs['detail'].$el.scrollHeight + 'px'
          this.detailStyles.opacity = '1'
        } else if (mode === 'hide') {
          this.detailStyles.transitionDelay = '0ms'
          this.detailStyles.height = '0px'
          this.detailStyles.opacity = '0'
        }
      },
      calcPosition() {
        let index = this.activeProject.index/2
        if(index % 1 != 0) {
          this.detailStyles.gridRow = Math.ceil(index) + 1
        } else {
          this.detailStyles.gridRow = Math.ceil(index) + 2
        }
      }
    },
    watch: {
      breakpoint() {
        this.setStyles('hide')
        this.activeProject = null
      }
    },
    mounted() {
      this.$nextTick(()=>{
        if(this.$store.state.self) {
          this.selectProject(this.$store.state.self)
        }
      });
    }
  }
</script>