export default {
  state: {
    loaded: false,
    breakpoint: '',
    fullURL: process.env.BASE_URL,
    craftLoaded: false,
    craftPageData: null,
    pageTitle: null,
    featuredNewsLoaded: false,
    featuredNews: null,
    newsCategories: null,
    newsYears: null,
    peopleCategories: null,
    addressLine1: null,
    addressLine2: null,
    linkedInURL: null,
    phone: null,
    email: null,
    child: null,
    self: null,
    overlayData: false,
    menuStatus: false,
    newLoad: true,
    menuData: {
      investments: {
        name: 'investments',
        children: ['direct-investments', 'alvarium', 'property-development', 'investment-property']
      },
      familyOffice: {
        name: 'family-office'
      },
      foundation: {
        name: 'foundation',
        children: ['our-foundation','our-giving','our-impact','about-us']
      },
      news: {
        name: 'news'
      },
      contact: {
        name: 'contact'
      }
    }
  },
  mutations: {
    setAddressLine1 (state, payload){
      state.addressLine1 = payload
    },
    setAddressLine2 (state, payload){
      state.addressLine2 = payload
    },
    setPhone (state, payload){
      state.phone = payload
    },
    setEmail (state, payload){
      state.email = payload
    },
    setLinkedIn (state, payload){
      state.linkedInURL = payload
    },
    setFeaturedNewsLoaded (state, payload){
      state.featuredNewsLoaded = payload
    },
    setFeaturedNews (state, payload){
      state.featuredNews = payload
    },
    setNewsCategories (state, payload){
      state.newsCategories = payload
    },
    setNewsYears (state, payload){
      state.newsYears = payload
    },
    setPeopleCategories (state, payload){
      state.peopleCategories = payload
    },
    setCraftLoaded (state, payload){
      state.craftLoaded = payload
    },
    setPageTitle (state, payload){
      state.pageTitle = payload
    },
    setCraftPageData (state, payload){
      state.craftPageData = payload
    },
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setChild (state, payload){
      state.child = payload
    },
    setSelf (state, payload){
      state.self = payload
    },
    setOverlayData (state, payload){
      state.overlayData = payload
    },
    setMenuStatus (state, payload){
      state.menuStatus = payload
    },
    setNewLoad (state, payload){
      state.newLoad = payload
    },
    setCSRFTokenName (state, payload){
      state.csrfTokenName = payload
    },
    setCSRFToken (state, payload){
      state.csrfToken = payload
    },
  },
  actions: {
  }
}